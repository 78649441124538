import { useAuth } from '../contexts';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
export const useTokenUtils = () => {
  const { token } = useAuth();
  const decodedToken = token ? jwtDecode(token) : null;

  const [isAdminRole] = useState(
    decodedToken?.resource_access?.admin_oli_move?.roles.some(
      (role) => role === 'oli_move_admin'
    )
  );

  const [isOperatorRole] = useState(
    decodedToken?.resource_access?.dashboard_oli_move?.roles.some(
      (role) => role === 'oli_move_operator'
    )
  );

  const [isOperatorRoleWithTypeOperator] = useState(
    isOperatorRole && decodedToken?.customer_type === 'Operator'
  );

  const [isOperatorRoleWithTypePartner] = useState(
    isOperatorRole && decodedToken?.customer_type === 'Partner'
  );
  const [cpoIdsFromToken] = useState(decodedToken?.cpo_id);

  return {
    isAdminRole,
    isOperatorRole,
    isOperatorRoleWithTypeOperator,
    isOperatorRoleWithTypePartner,
    cpoIdsFromToken
  };
};
