import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import MuiSelect from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import shadows from '@mui/material/styles/shadows';
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, Select } from 'formik-mui';
import { TextField } from '@mui/material';
import { useAuth } from '../../../../services/contexts/AuthContext';
import LightTooltip from './LightTooltip';
import api from '../../../../Middleware/api/api';
import { useTranslation } from 'react-i18next';
import { userTypeListWithTranlations } from '../../../../services/utils/translations-constants.js';
import { useTokenUtils } from '../../../../services/utils/tokenUtils';
/**
 * @param {PropTypes.InferProps<UserCreationFormLocation.propTypes>} props
 */
const UserCreationFormLocation = ({
  setPayloadOne,
  confirmPayload,
  payload,
  submit,
  setSubmit,
  setCanNext,
  CreateUpdate,
}) => {
  let defaultUserType = 'REGULAR';
  let defaultAuthType = 'APP';
  let authTypeForLocalAdmin = 'RFID';
  let authTypeForGlobalAdmin = 'RFID';
  let defaultSelectedRfIdType =
    payload?.rfidSerialId?.length > 0 ? 'SN' : 'TAG';
  const { t } = useTranslation();
  const { token } = useAuth();
  const {
    isAdminRole,
    isOperatorRoleWithTypeOperator,
    isOperatorRoleWithTypePartner,
    cpoIdsFromToken,
  } = useTokenUtils();
  const [selectedLocationObj, setSelectedLocationObj] = useState(null);
  const [parkingLotsOfLocation, setParkingLotsOfLocation] = useState([]);
  const [parkingInfoLocationDetails, setParkingInfoLocationDetails] =
    useState(null);
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [minStartDate, setMinStartDate] = useState('');

  const userLocationFormRef = useRef();
  const [operators, setOperators] = useState([]);

  const userTypeListDropDownForOperator = ['REGULAR', 'LOCAL_ADMIN'];
  const userTypeListDropDownForAdmin = [
    'REGULAR',
    'LOCAL_ADMIN',
    'GLOBAL_ADMIN',
  ];
  const [userTypeList] = useState(
    isAdminRole ? userTypeListDropDownForAdmin : userTypeListDropDownForOperator
  );
  const [selectedUserType, setSelectedUserType] = useState(
    payload?.userType || defaultUserType
  );

  const [selectedAuthType, setSelectedAuthType] = useState(
    payload?.authType
      ? payload?.authType
      : payload?.userType === 'LOCAL_ADMIN' ||
          selectedUserType === 'LOCAL_ADMIN' ||
          payload?.userType === 'GLOBAL_ADMIN' ||
          selectedUserType === 'GLOBAL_ADMIN'
        ? 'RFID'
        : defaultAuthType
  );

  const [selectedRfIdType, setSelectedRfIdType] = useState(
    defaultSelectedRfIdType
  );
  const [selectedParkingLots, setSelectedParkingLots] = useState(
    payload?.parkingLotNumbers || []
  );

  const [selectedCpoId, setSelectedCpoId] = useState(
    payload?.cpoId
      ? payload?.cpoId
      : isOperatorRoleWithTypeOperator
        ? cpoIdsFromToken[0]
        : isOperatorRoleWithTypePartner
          ? ''
          : ''
  );

  const determineSelectedLocationIdNameInitialValue = () => {
    if (selectedLocationObj) {
      return selectedLocationObj?.locationIdName;
    } else if (payload) {
      return payload?.locationId + ':' + payload?.locationName;
    } else '';
  };
  const [selectedLocationIdName, setSelectedLocationIdName] = useState(
    determineSelectedLocationIdNameInitialValue()
  );
  useEffect(() => {
    getParkingInfoLocationDetails();
    getOperators();
  }, []);

  const resetLocationAndParking = () => {
    let locationId = '';
    let locationName = '';
    let selectedParkingInfo = null;
    setSelectedLocationIdName('');
    setParkingLotsOfLocation(selectedParkingInfo?.parkingLots);
    setSelectedLocationObj({
      locationId,
      locationName,
      ...selectedParkingInfo,
    });
    setSelectedParkingLots([]);
  };

  const getParkingInfoLocationDetails = async () => {
    let rows = null;
    try {
      api.fetchLocationsForDropdowns(token).then(
        (response) => {
          if (response.data.data == null) {
            rows = [];
          } else {
            rows = response.data.data;
            setParkingInfoLocationDetails(response?.data?.data);
            if (CreateUpdate === 'update' && payload?.userType === 'REGULAR') {
              const existingSelectedLocation = response?.data?.data?.find(
                ({ locationId }) => {
                  return locationId === payload?.locationId;
                }
              );
              const [locationId, locationName] =
                existingSelectedLocation?.locationIdName.split(':');
              setParkingLotsOfLocation(existingSelectedLocation?.parkingLots);
              setSelectedLocationObj({
                locationId,
                locationName,
                ...existingSelectedLocation,
              });
            }
          }
        },
        () => {
          rows = [];
        }
      );
    } catch (exception) {
      rows = [];
    }
  };

  const getOperators = async () => {
    try {
      api.fetchOperators(token, 0, 10000).then(
        (response) => {
          if (response.data.data == null) {
            setOperators([]);
          } else {
            setOperators(response.data.data.response);
          }
        },
        () => {
          setOperators([]);
        }
      );
    } catch (exception) {
      setOperators([]);
    }
  };

  useEffect(() => {
    if (submit > 0) document.getElementById('subOne').click();
    setSubmit(0);
    let today = new Date();
    let yyyy = today.getFullYear();
    setMinStartDate(
      yyyy + today.toLocaleString('en-US', { month: '2-digit' }) + '01'
    );
    userLocationFormRef.current.setFieldError('userType', false);
  }, [submit, minStartDate]);

  const initialValues = {
    userType: selectedUserType
      ? selectedUserType
      : payload?.userType
        ? payload?.userType
        : defaultUserType,
    cpoId: payload?.cpoId || selectedCpoId,
    locationIdName: selectedLocationIdName
      ? selectedLocationIdName
      : payload
        ? payload?.locationId + ':' + payload?.locationName
        : '',
    locationId: selectedLocationObj
      ? selectedLocationObj?.locationId
      : payload
        ? payload?.locationId
        : null,
    locationName: selectedLocationObj
      ? selectedLocationObj?.locationName
      : payload
        ? payload?.locationName
        : null,
    authType: selectedAuthType
      ? selectedAuthType
      : payload?.authType
        ? payload?.authType
        : defaultAuthType,
    parkingLotNumbers: selectedParkingLots
      ? selectedParkingLots
      : payload?.parkingLotNumbers
        ? payload?.parkingLotNumbers
        : [],
    cpoPaymentPreference: payload?.cpoPaymentPreference || '',
    rfidType: selectedRfIdType,
    rfidTags:
      payload?.rfidTags?.length > 1
        ? payload?.rfidTags.join()
        : payload?.rfidTags?.length > 0
          ? payload?.rfidTags[0]
          : '',
    rfidSTags:
      payload?.rfidSerialId?.length > 1
        ? payload?.rfidSerialId.join()
        : payload?.rfidSerialId?.length > 0
          ? payload?.rfidSerialId[0]
          : '',
  };

  /**
   * @method
   * @param {Props['values']} values
   * @param {Props['handleChange']} handleChange
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void}
   */

  const handleUserTypeSelect =
    (setFieldValue, setFieldTouched, handleChange, setFieldError, values) =>
    (event) => {
      const newValue = event.target.value;
      setSelectedUserType(newValue);
      if (newValue === 'LOCAL_ADMIN') {
        // first we reset the location parking lot
        resetLocationAndParking();
        // resolve the auth type to rfid
        setSelectedAuthType(authTypeForLocalAdmin);
      } else if (newValue === 'GLOBAL_ADMIN') {
        // first we reset the location parking lot
        resetLocationAndParking();
        // resolve the auth type to rfid
        setSelectedAuthType(authTypeForGlobalAdmin);
      }
    };

  const handleCpoIdChange =
    (setFieldValue, setFieldTouched, handleChange, setFieldError) =>
    (event) => {
      const newValue = event.target.value;
      setSelectedCpoId(newValue);
    };

  const handleLocationSelect =
    (setFieldValue, setFieldTouched, handleChange, setFieldError) =>
    (event) => {
      const newLocationIdNameSelect = event.target.value;
      const newLocationSelect = parkingInfoLocationDetails?.find(
        ({ locationIdName }) => {
          return locationIdName === newLocationIdNameSelect;
        }
      );
      setSelectedLocationIdName(newLocationIdNameSelect);
      // we select locations only when the userType is REGULAR(Location Sepecific)
      const [locationId, locationName] =
        // eslint-disable-next-line no-unsafe-optional-chaining
        newLocationSelect?.locationIdName.split(':');
      setParkingLotsOfLocation(newLocationSelect?.parkingLots);
      setSelectedLocationObj({
        locationId,
        locationName,
        ...newLocationSelect,
      });
      if (selectedUserType === 'REGULAR') {
        setSelectedCpoId(newLocationSelect?.cpoId);
      } else {
        setSelectedCpoId('');
      }
    };

  const handleParkingLotSelect =
    (setFieldValue, setFieldError, setFieldTouched) => (event) => {
      const { value } = event.target;
      const selectedParkingLots =
        typeof value === 'string' ? value.split(',') : value;
      setSelectedParkingLots(
        selectedParkingLots.includes('all')
          ? selectedLocationObj.parkingLots
          : selectedParkingLots
      );
      setFieldTouched('parkingLotNumbers', true, true);
      setFieldError('parkingLotNumbers', 'requiured');
    };

  const handleAuthTypeChange = (handleChange) => (event) => {
    const newAuthTypeSelect = event.target.value;
    setSelectedAuthType(newAuthTypeSelect);
    if (newAuthTypeSelect === 'APP') {
      setSelectedRfIdType(null);
    } else if (
      newAuthTypeSelect === 'RFID' ||
      newAuthTypeSelect === 'APP_AND_RFID'
    ) {
      setSelectedRfIdType(defaultSelectedRfIdType);
    }
  };
  const handleRFIDTypeChange = (handleChange) => (event) => {
    const newRfIdTypeSelect = event.target.value;
    setSelectedRfIdType(newRfIdTypeSelect);
  };
  const openTooltip = (name) => () => setTooltipOpen(name);
  const closeTooltip = () => setTooltipOpen('');

  const customHandleBlur = (handleBlur) => (event) => {
    closeTooltip();
    handleBlur(event);
  };
  function splitTheString(CommaSepStr) {
    var ResultArray = null;

    // Check if the string is null or so.
    if (CommaSepStr != null) {
      var SplitChars = ',';
      // Check if the string has comma of not will go to else
      if (CommaSepStr.indexOf(SplitChars) >= 0) {
        ResultArray = CommaSepStr.split(SplitChars);
      } else {
        // The string has only one value, and we can also check
        // the length of the string or time and cross-check too.
        ResultArray = [CommaSepStr];
      }
    }
    return ResultArray;
  }

  const determineCpoPaymentPreferenceOnSubmitPage = (
    originalCpoPaymentPreference
  ) => {
    const defaultPreference = ['NO_PAYMENT_METHOD'];

    if (selectedUserType === 'GLOBAL_ADMIN') {
      return defaultPreference;
    }

    if (
      selectedAuthType === 'RFID' &&
      originalCpoPaymentPreference?.includes('STRIPE_SEPA')
    ) {
      const filteredPreferences = originalCpoPaymentPreference.filter(
        (pref) => pref !== 'STRIPE_SEPA'
      );
      return filteredPreferences.length
        ? filteredPreferences
        : defaultPreference;
    }

    return originalCpoPaymentPreference;
  };

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const [locationId, locationName] = selectedLocationObj?.locationIdName
      ? selectedLocationObj.locationIdName.split(':')
      : [null, null];
    let originalCpoPaymentPreference = operators
      .filter((item) => item.cpoId === selectedCpoId)
      .flatMap((item) => item.cpoPaymentPreference ?? ['NO_PAYMENT_METHOD']);
    let paymentPreference = determineCpoPaymentPreferenceOnSubmitPage(
      originalCpoPaymentPreference
    );

    const payloadVal = {
      userType: selectedUserType,
      locationIdName: selectedLocationIdName,
      locationId: locationId,
      locationName: locationName,
      cpoPaymentPreference: paymentPreference,
      originalCpoPaymentPreference: originalCpoPaymentPreference,
      parkingLotNumbers: selectedParkingLots,
      cpoId: selectedCpoId,
      authType: selectedAuthType,
      rfidType: selectedRfIdType,
      rfidTags:
        selectedAuthType != defaultAuthType && selectedRfIdType == 'TAG'
          ? splitTheString(values?.rfidTags)
          : null,
      rfidSTags:
        selectedAuthType != defaultAuthType && selectedRfIdType != 'TAG'
          ? splitTheString(values?.rfidSTags)
          : null,
    };
    setPayloadOne(payloadVal);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, true);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, true, true);
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        cpoId: Yup.string().required(t('LBLOpIdreq')).label(t('LBLOpId')),
        userType: Yup.string()
          .required(t('LBLUserTypeReq'))
          .label(t('LBLUserType')),
        locationIdName:
          selectedUserType !== 'REGULAR'
            ? ''
            : Yup.string().required(t('locReq')).label(t('LBLLocation')),
        locationId:
          selectedUserType !== 'REGULAR'
            ? ''
            : Yup.string().required(t('locReq')).label(t('LBLLocation')),
        parkingLotNumbers:
          selectedUserType !== 'REGULAR'
            ? null
            : Yup.array()
                .min(1, t('parkingnumreq'))
                .required(t('parkingnumreq'))
                .label(t('LBLParkingLotNum')),

        rfidTags:
          selectedAuthType === defaultAuthType
            ? Yup.string().label('RFID-Tags')
            : selectedRfIdType == 'TAG'
              ? Yup.string().required(t('rfIdreq')).label('RFID-Tags')
              : Yup.string().label('RFID-Tags'),

        rfidSTags:
          selectedAuthType === defaultAuthType
            ? Yup.string().label('RFID-SN')
            : selectedRfIdType != 'TAG'
              ? Yup.string().required(t('rfIdSreq')).label('RFID-SN')
              : Yup.string().label('RFID-SN'),
      }),
    [selectedAuthType, selectedRfIdType, selectedUserType]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
      innerRef={userLocationFormRef}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        handleChange,
        isSubmitting,
        handleBlur,
        isValid,
      }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid && operators.length > 0)}

            <Stack mb={2} direction="row" spacing={2}>
              <Box width="20%">
                <LightTooltip
                  open={tooltipOpen === 'userType'}
                  onOpen={openTooltip('userType')}
                  onClose={closeTooltip}
                  placement="bottom-start"
                  title={
                    <>
                      <Typography>
                        {t('LBLLocalAdminUserTypeDescription')}
                      </Typography>
                      <Typography>
                        <>
                          <br></br>
                        </>
                      </Typography>
                      <Typography>
                        {t('LBLRegularUserTypeDescription')}
                      </Typography>
                    </>
                  }
                >
                  <InfoIcon
                    color="action"
                    fontSize="small"
                    sx={{ position: 'absolute', ml: -6, mt: 2 }}
                  />
                </LightTooltip>

                <Field
                  disabled={CreateUpdate === 'update'}
                  component={Select}
                  formControl={{
                    required: true,
                    fullWidth: true,
                    size: 'small',
                    margin: 'none',
                  }}
                  onChange={handleUserTypeSelect(
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    setFieldError,
                    values
                  )}
                  id="userTypeid"
                  name="userType"
                  label={t('LBLUserType')}
                  displayEmpty={true}
                  defaultValue={selectedUserType}
                  value={selectedUserType}
                  MenuProps={{
                    PaperProps: { sx: { boxShadow: shadows[3] } },
                  }}
                >
                  {userTypeList?.map((usertype) => (
                    <MenuItem key={usertype} value={usertype}>
                      <ListItemText>
                        {userTypeListWithTranlations(t)[usertype] || userType}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Box>

              <Box width="20%">
                {values.userType === 'REGULAR' ||
                selectedUserType === 'REGULAR' ||
                isOperatorRoleWithTypeOperator ||
                CreateUpdate === 'update' ? null : (
                  <Field
                    disabled={
                      values.userType === 'REGULAR' ||
                      selectedUserType === 'REGULAR' ||
                      isOperatorRoleWithTypeOperator ||
                      CreateUpdate === 'update'
                    }
                    component={Select}
                    formControl={{
                      fullWidth: true,
                      size: 'small',
                      margin: 'none',
                    }}
                    onChange={handleCpoIdChange(
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      setFieldError
                    )}
                    id="operatorId_Id"
                    name="cpoId"
                    label={t('LBLOpId')}
                    displayEmpty={true}
                    defaultValue={selectedCpoId}
                    value={selectedCpoId}
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {(Array.isArray(operators) ? operators : [])
                      .sort((a, b) => a.cpoId.localeCompare(b.cpoId))
                      .map((operator, index) => (
                        <MenuItem key={index} value={operator.cpoId}>
                          {operator.cpoId + ': ' + operator.cpoName}
                        </MenuItem>
                      ))}
                  </Field>
                )}
              </Box>
            </Stack>

            {selectedUserType === 'REGULAR' ? (
              <Stack mb={5} direction="row" spacing={8}>
                <Box width="100%">
                  <Field
                    disabled={payload?.contractStatus === 'ACTIVE'}
                    required
                    component={Select}
                    formControl={{
                      required: true,
                      fullWidth: true,
                      size: 'small',
                      margin: 'none',
                    }}
                    onChange={handleLocationSelect(
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      setFieldError
                    )}
                    displayEmpty={true}
                    defaultValue={''}
                    value={selectedLocationIdName || ''}
                    id="standort"
                    name="locationIdName"
                    label={t('LBLLocation')}
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {(Array.isArray(parkingInfoLocationDetails)
                      ? parkingInfoLocationDetails
                      : []
                    )
                      .sort((a, b) => a.locationId.localeCompare(b.locationId))
                      .map((location) => (
                        <MenuItem
                          key={location.locationIdName}
                          value={location.locationIdName}
                        >
                          {location.locationIdName}
                        </MenuItem>
                      ))}
                  </Field>
                </Box>
                <FormControl
                  fullWidth
                  size="small"
                  margin="none"
                  error={Boolean(
                    (selectedUserType === 'REGULAR' &&
                      selectedLocationObj?.parkingLots?.length === 0) ||
                      (selectedUserType === 'REGULAR' &&
                        selectedParkingLots?.length === 0 &&
                        touched?.parkingLotNumbers)
                  )}
                >
                  <InputLabel id="parkplatznummer-label">
                    {t('LBLParkingLotNum')}
                  </InputLabel>
                  <MuiSelect
                    multiple
                    fullWidth
                    required={
                      selectedUserType === 'REGULAR' &&
                      selectedParkingLots?.length === 0 &&
                      touched.parkingLotNumbers
                    }
                    displayEmpty={false}
                    defaultValue={[]}
                    value={selectedParkingLots}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={handleParkingLotSelect(
                      setFieldValue,
                      setFieldError,
                      setFieldTouched
                    )}
                    onBlur={(event) => {
                      setFieldTouched('parkingLotNumbers', true, true);
                    }}
                    id="parkplatznummer"
                    labelId="parkplatznummer-label"
                    name="parkingLotNumbers"
                    label={t('LBLParkingLotNum')}
                    helpertext={
                      selectedUserType === 'REGULAR' &&
                      parkingLotsOfLocation?.parkingLots?.length === 0
                        ? t('noparkingnumas')
                        : errors.parkingLot
                    }
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {parkingLotsOfLocation?.map((parkingLot) => (
                      <MenuItem key={parkingLot} value={parkingLot}>
                        <Checkbox
                          size="small"
                          checked={Boolean(
                            values.parkingLotNumbers.includes(parkingLot)
                          )}
                        />
                        <ListItemText>{parkingLot}</ListItemText>
                      </MenuItem>
                    ))}
                  </MuiSelect>
                  <FormHelperText
                    error={Boolean(
                      selectedUserType === 'REGULAR' &&
                        selectedLocationObj?.parkingLots?.length === 0
                    )}
                    sx={{
                      display:
                        selectedUserType === 'REGULAR' &&
                        selectedLocationObj?.parkingLots?.length === 0
                          ? 'show'
                          : 'none',
                    }}
                  >
                    {t('noparkingnumas')}
                  </FormHelperText>
                  <FormHelperText
                    error={Boolean(
                      selectedUserType === 'REGULAR' &&
                        selectedParkingLots?.length === 0 &&
                        touched?.parkingLotNumbers
                    )}
                    sx={{
                      display:
                        selectedUserType === 'REGULAR' &&
                        selectedParkingLots?.length === 0 &&
                        touched?.parkingLotNumbers
                          ? 'show'
                          : 'none',
                    }}
                  >
                    {t('parkingnumreq')}
                  </FormHelperText>
                </FormControl>
              </Stack>
            ) : null}

            <Stack mb={5} direction="row" spacing={8}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Field
                  margin="none"
                  sx={{ width: '100%' }}
                  component={RadioGroup}
                  aria-labelledby="autorisierung-uber-label"
                  name="authType"
                  defaultValue={selectedAuthType}
                  value={selectedAuthType}
                  onChange={handleAuthTypeChange(handleChange)}
                >
                  <Typography
                    fontWeight={700}
                    variant="subtitle1"
                    id="autorisierung-uber-label"
                  >
                    {t('authuser')}
                  </Typography>
                  <FormControlLabel
                    value="APP"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="OLI Move App"
                    disabled={isSubmitting || selectedUserType !== 'REGULAR'}
                  />
                  <FormControlLabel
                    value="RFID"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="RFID"
                    disabled={isSubmitting}
                  />
                  <FormControlLabel
                    value="APP_AND_RFID"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="OLI Move App und RFID"
                    disabled={isSubmitting || selectedUserType !== 'REGULAR'}
                  />
                </Field>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                {selectedAuthType !== 'APP' ? (
                  <Field
                    margin="none"
                    sx={{ width: '100%' }}
                    component={RadioGroup}
                    aria-labelledby="autorisierung-uber-label"
                    name="rfidType"
                    defaultValue={selectedRfIdType}
                    value={selectedRfIdType}
                    onChange={handleRFIDTypeChange(handleChange)}
                  >
                    <Typography
                      fontWeight={700}
                      variant="subtitle1"
                      id="autorisierung-uber-label"
                    >
                      {t('tagdetails')}
                    </Typography>
                    <FormControlLabel
                      value="TAG"
                      sx={{ width: 'fit-content' }}
                      control={<Radio disabled={isSubmitting} />}
                      label="Tag"
                      disabled={
                        isSubmitting || selectedAuthType == defaultAuthType
                      }
                    />
                    <FormControlLabel
                      value="SN"
                      sx={{ width: 'fit-content' }}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('rfIdSN')}
                      disabled={
                        isSubmitting || selectedAuthType == defaultAuthType
                      }
                    />
                  </Field>
                ) : null}
              </Stack>

              {selectedAuthType === 'APP' ? null : (
                <>
                  <Box position="relative" width="100%">
                    <LightTooltip
                      open={tooltipOpen === 'rfid-tags'}
                      onOpen={openTooltip('rfid-tags')}
                      onClose={closeTooltip}
                      placement="bottom-start"
                      title={
                        <>
                          <Typography>{t('commasep')}</Typography>
                          <Typography>{t('example')}:</Typography>
                          <Typography>Tag-1, Tag-2, Tag-3</Typography>
                        </>
                      }
                    >
                      <InfoIcon
                        color="action"
                        fontSize="small"
                        sx={{ position: 'absolute', ml: -6, mt: 2 }}
                      />
                    </LightTooltip>

                    <TextField
                      margin="none"
                      id="rfid-tags"
                      label="RFID-Tags"
                      name="rfidTags"
                      variant="outlined"
                      required={
                        values.authType != defaultAuthType &&
                        values.rfidType === 'TAG'
                      }
                      disabled={
                        values.authType == defaultAuthType ||
                        values.rfidType != 'TAG'
                      }
                      value={values.rfidTags}
                      sx={{
                        '& .Mui-disabled': {
                          bgcolor: 'grey.400',
                        },
                      }}
                      onChange={handleChange}
                      onFocus={openTooltip('rfid-tags')}
                      onBlur={customHandleBlur(handleBlur)}
                      error={touched.rfidTags && Boolean(errors.rfidTags)}
                      helperText={touched.rfidTags && errors.rfidTags}
                      fullWidth
                      multiline
                      my={2}
                    />
                  </Box>
                  <Box position="relative" width="100%">
                    <LightTooltip
                      open={tooltipOpen === 'rfidS-tags'}
                      onOpen={openTooltip('rfidS-tags')}
                      onClose={closeTooltip}
                      placement="bottom-start"
                      title={
                        <>
                          <Typography>{t('commasepS')}</Typography>
                          <Typography>{t('example')}:</Typography>

                          <Typography>SN-1, SN-2, SN-3</Typography>
                        </>
                      }
                    >
                      <InfoIcon
                        color="action"
                        fontSize="small"
                        sx={{ position: 'absolute', ml: -6, mt: 2 }}
                      />
                    </LightTooltip>
                    <TextField
                      margin="none"
                      id="rfid-SN"
                      name="rfidSTags"
                      label="RFID-SN"
                      variant="outlined"
                      disabled={
                        values.authType === defaultAuthType ||
                        selectedRfIdType === 'TAG'
                      }
                      required={
                        values.authType != defaultAuthType &&
                        selectedRfIdType != 'TAG'
                      }
                      value={values.rfidSTags}
                      sx={{
                        '& .Mui-disabled': {
                          bgcolor: 'grey.400',
                        },
                      }}
                      onChange={handleChange}
                      onFocus={openTooltip('rfidS-tags')}
                      onBlur={customHandleBlur(handleBlur)}
                      error={touched.rfidSTags && Boolean(errors.rfidSTags)}
                      helperText={touched.rfidSTags && errors.rfidSTags}
                      fullWidth
                      multiline
                      my={2}
                    />
                  </Box>
                </>
              )}
            </Stack>

            <Button sx={{ display: 'none' }} id="subOne" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationFormLocation.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadOne: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationFormLocation;