// eslint-disable-next-line no-undef
const axios = require('axios');

// Create an axios instance
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = removeEmptyStringsAndNull(config.data);
  }
  return config;
});

// Function to recursively remove empty values from an object
const removeEmptyStringsAndNull = (obj) => {
  if (obj === null || obj === undefined) return null;
  if (typeof obj !== 'object') return obj;
  if (Array.isArray(obj)) {
    const cleanedArray = obj
      .map(removeEmptyStringsAndNull)
      .filter((value) => value !== null && value !== undefined);

    return cleanedArray.length ? cleanedArray : null;
  }

  const cleanedObj = Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [key, removeEmptyStringsAndNull(value)])
      .filter(
        ([_, cleanedValue]) =>
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          cleanedValue !== ''
      )
  );

  return Object.keys(cleanedObj).length ? cleanedObj : null;
};

// fetch charging stations
const fetchStations = (accessToken, cpoId) => {
  const STATION_ENDPOINT = `${window._env_.REACT_APP_API_ROOT_URL}/cpos/${cpoId}/charging-stations`;
  return axiosInstance.get(STATION_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// post contact us form data
const postFeedback = (accessToken, payload) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/mail/send`;
  return axiosInstance.post(API_URL, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

//get a particular billing record starting from its billingNr
const fetchBilling = (billingNr, token) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/get-bill/${billingNr}`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      billingNr: billingNr,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//for the betreiber anlegen form
const createNewOperator = (accessToken, payload) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos/create-operator`;
  return axiosInstance.post(API_URL, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const createNewLocation = (accessToken, payload) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/location`;
  return axiosInstance.post(API_URL, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchLocations = (accessToken, currentPage, PAGE_SIZE) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/get-by-cpo?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchOperatorContracts = (accessToken, currentPage, PAGE_SIZE) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/contracts/operator?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchGraph = (accessToken, params) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/kpi/get-graph?${params.toString()}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchLocationsForDownloadCenter = (accessToken) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/get-by-cpo-for-download-center`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchdashboardusersbyid = (
  accessToken,
  currentPage,
  PAGE_SIZE,
  customerId
) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos/get-dashboard-user/${customerId}?page=${currentPage}&size=${PAGE_SIZE}`;

  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchLocationsForDropdowns = (accessToken) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/parking-info`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchLocationById = (accessToken, locationId) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/${locationId}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getDashboardUsers = (accessToken, currentPage, pageSize) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos/get-all-customer?page=${currentPage}&size=${pageSize}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
const fethcChargingHistory = (accessToken, params, currentPage, pageSize) => {
  // create a function to fetch locations and use it on cockpit
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/get-charging-history?${params.toString()}&page=${currentPage}&size=${pageSize}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchWallboxTypes = (accessToken) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/locations/wallboxTypes`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchOperators = (accessToken, currentPage, PAGE_SIZE) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchStationsList = (accessToken, currentPage, PAGE_SIZE) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/get-by-cpo?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchUsersByLocationId = (
  accessToken,
  locationId,
  currentPage,
  PAGE_SIZE
) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/location/${locationId}/contracts?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchCharginStationByLocationId = (
  accessToken,
  locationId,
  currentPage,
  PAGE_SIZE
) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/get-by-cpo?page=${currentPage}&size=${PAGE_SIZE}&locationId=${locationId}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchBills = (accessToken, params) => {
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/get-bills/operator`;
  return axiosInstance.get(API_URL, {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchOperatorDetails = (accessToken, customerId) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos/${customerId}/operator-details`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchContractByCustomerId = (accessToken, customerId) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/contracts/${customerId}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const fetchDashboardUsersByCustomerId = (accessToken, customerId) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/cpos/get-customer/${customerId}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const changeAvailability = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/ocpp-client/change-availability`;
  return axiosInstance.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const changeParkingLot = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/update-connector-status`;
  return axiosInstance.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const createCP = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/create-cp`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const refreshTokenRequest = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_USER_SERVICE}/refresh-token`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Change configuration endpoint for a charging station
const changeConfiguration = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/ocpp-client/change-configuration`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Change configuration endpoint for a charging station
const cleanChargingRequests = (accessToken, minutesThreshold) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/charging-requests/clean?minutesThreshold=${minutesThreshold}`;
  return axiosInstance.put(API_URL, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Change configuration endpoint for a charging station
const deleteUserContract = (accessToken, contractId) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/delete-contract/${contractId}`;
  return axiosInstance.delete(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// get all RFIDs
const getAllRFIDs = (accessToken, currentPage, PAGE_SIZE) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/auth/get-rfid-tags?page=${currentPage}&size=${PAGE_SIZE}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// create  RFID
const createRFID = (accessToken, reqBody) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/auth/save-rfid-tags`;
  return axiosInstance.post(API_URL, reqBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Create connectors using csv at charging station page
const createConnectorViaCSV = async (accessToken, requestBody, separator) => {
  const API_URL =
    `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/upload?csvSeparator=` +
    separator;
  return await fetch(API_URL, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: requestBody,

  }).then(r => r.json());
};


const changeBillingStatus = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/billing-status-update`;
  return axiosInstance.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const retryStripeSepaPayment = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/stripe-sepa-payments/generate-for-bill`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const sendBillsPdfEmail = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/send-bill-email`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const downloadBills = (accessToken, requestBody) => {
  
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/download-bill`;
  return axiosInstance.post(API_URL, requestBody, {
    responseType: 'arraybuffer', 
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const regenerateIncorrectBills = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/regenerate-bills`;
  return axiosInstance.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const terminateContract = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/terminate-contract`;
  return axiosInstance.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

//get all billings for userId
const fetchCSV = (format, startDate, endDate, locationIds, token) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/charging-history/export`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      exportType: format,
      startDate: startDate,
      endDate: endDate,
      locationIds: locationIds,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//get ERP CSV file
const fetchErpCsv = (
  startDate,
  endDate,
  format,
  locationIds,
  columnSeparator,
  germanDecimalFormat,
  cpoId,
  paymentMethod,
  token
) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/csv`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      startDate: startDate,
      endDate: endDate,
      exportType: format,
      locationIds: locationIds,
      columnSeparator: columnSeparator,
      germanDecimalFormat: germanDecimalFormat,
      cpoId: cpoId,
      paymentMethod: paymentMethod,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//get ERP XML file
const fetchErpXml = (
  startDate,
  endDate,
  cpoId,
  locationIds,
  paymentMethod,
  token
) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/generate/xml`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      startDate: startDate,
      endDate: endDate,
      cpoId: cpoId,
      locationIds: locationIds,
      paymentMethod: paymentMethod,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//get standard CSV file
const fetchBillsCSV = (startDate, endDate, exportType, locationIds, token) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/standard-csv`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      startDate: startDate,
      endDate: endDate,
      exportType: exportType,
      locationIds: locationIds,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//export Connector Passwords
const exportConnectorPasswords = (connectors, token) => {
  const BILL_ENDPOINT = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/export-csv`;
  return axiosInstance.get(BILL_ENDPOINT, {
    params: {
      connectors: connectors,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

// get Charging history as CSV on user Detail page
const fetchChargingHistoryCSV = (
  startDate,
  endDate,
  contractKey,
  exportType,
  token
) => {
  const CHARGING_HISTORY_ENDPOINT = `${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/charging-history/export`;
  return axiosInstance.get(CHARGING_HISTORY_ENDPOINT, {
    params: {
      startDate: startDate,
      endDate: endDate,
      contractKey: contractKey,
      exportType: exportType,
    },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

//get Charging Station Info and KPI
const chargingStationInfoAndKPI = (token, connectorId) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/connectors/${connectorId}`;
  return axiosInstance.get(API_URL, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

const redoStripeSepa = (accessToken, contractKey) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/reconfigure-stripe-sepa/${contractKey}`;
  return axiosInstance.post(API_URL, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// eslint-disable-next-line
export default {
  fetchStations: fetchStations,
  postFeedback: postFeedback,
  fetchBilling: fetchBilling,
  createNewOperator: createNewOperator,
  createNewLocation: createNewLocation,
  fetchLocations: fetchLocations,
  fetchGraph: fetchGraph,
  fetchLocationsForDropdowns: fetchLocationsForDropdowns,
  fetchdashboardusersbyid: fetchdashboardusersbyid,
  fetchLocationById: fetchLocationById,
  getDashboardUsers: getDashboardUsers,
  fethcChargingHistory: fethcChargingHistory,
  fetchWallboxTypes: fetchWallboxTypes,
  fetchOperators: fetchOperators,
  fetchStationsList: fetchStationsList,
  fetchUsersByLocationId: fetchUsersByLocationId,
  fetchCharginStationByLocationId: fetchCharginStationByLocationId,
  fetchOperatorContracts: fetchOperatorContracts,
  fetchContractByCustomerId: fetchContractByCustomerId,
  fetchDashboardUsersByCustomerId: fetchDashboardUsersByCustomerId,
  changeAvailability: changeAvailability,
  changeParkingLot: changeParkingLot,
  createCP: createCP,
  refreshTokenRequest: refreshTokenRequest,
  changeBillingStatus: changeBillingStatus,
  terminateContract: terminateContract,
  fetchCSV: fetchCSV,
  fetchErpCsv: fetchErpCsv,
  fetchErpXml: fetchErpXml,
  fetchBillsCSV: fetchBillsCSV,
  fetchOperatorDetails: fetchOperatorDetails,
  fetchChargingHistoryCSV: fetchChargingHistoryCSV,
  chargingStationInfoAndKPI: chargingStationInfoAndKPI,
  retryStripeSepaPayment: retryStripeSepaPayment,
  fetchBills: fetchBills,
  sendBillsPdfEmail: sendBillsPdfEmail,
  downloadBills: downloadBills,
  regenerateIncorrectBills: regenerateIncorrectBills,
  redoStripeSepa: redoStripeSepa,
  fetchLocationsForDownloadCenter: fetchLocationsForDownloadCenter,
  changeConfiguration: changeConfiguration,
  createConnectorViaCSV: createConnectorViaCSV,
  cleanChargingRequests: cleanChargingRequests,
  deleteUserContract: deleteUserContract,
  getAllRFIDs: getAllRFIDs,
  createRFID: createRFID,
  exportConnectorPasswords: exportConnectorPasswords
};
