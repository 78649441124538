import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { contractManagement } from '../../../../services/utils/axios';
import { errorCodes } from '../../../../services/utils/errorCodes';
import { format } from 'date-fns';
import { useAuth } from '../../../../services/contexts/AuthContext';
import { userTypeListWithTranlations } from '../../../../services/utils/translations-constants.js';
import {
  base64ToArrayBuffer,
  getBase64,
} from '../../../../services/utils/pdf-byte';
import { useSWRConfig } from 'swr';
import { formatDecimal } from '../../../../services/utils/format-numbers';
import { useTranslation } from 'react-i18next';
import OverflowText from '../../../../components/OverflowText';

/**
 * @param {PropTypes.InferProps<NutzerAnlegen.propTypes>} props
 */

function ConfirmUser({
  payload,
  openFeedback,
  submit,
  setError,
  setrfidError,
  setrfidSError,
  setemailError,
  setEndCustomerId,
  setSubmit,
  editFlag,
  contract,
}) {
  const { token } = useAuth();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (submit > 0) {
      if (editFlag) {
        updateUser();
      } else {
        createUser();
      }
    }
    setSubmit(0);
  }, [submit]);
  if (!payload) return null;

  const regularWithBillingRenderData = {
    UserType:
      userTypeListWithTranlations(t)[payload?.userType] || payload?.userType,
    Name: payload.firstName + ' ' + payload.lastName,
    Rechnungsadresse: [
      payload.contractAddress?.careOf
        ? `c/o ${payload.contractAddress?.careOf}`
        : '',
      payload.contractAddress?.street,
      payload.contractAddress?.zipCode + ' ' + payload.contractAddress?.city,
    ],
    'E-Mail': payload.email,
    Standort: payload.locationId + ':' + payload?.locationName,
    Parkplaznummer: payload.parkingLotNumbers,
    'Authentifizierung über':
      payload.authType === 'APP'
        ? 'Mobile App'
        : payload.authType === 'APP_AND_RFID'
          ? 'APP, RFID'
          : 'RFID-Karte',
    'RFID-Tags': payload.rfidTags,
    'RFID-serial-numbers': payload.rfidSTags,
    Abrechnungsart:
      payload.billingType === 'WITH_BILLING' ? 'Kostenpflichtig' : 'Kostenfrei',
    'Nutzungs- und Ladestromvertrag': payload.contractInfo?.contractPDF?.name,
    Startdatum:
      payload.contractInfo?.startDate != undefined
        ? format(new Date(payload.contractInfo?.startDate), 'dd.MM.yyyy')
        : null,
    Enddatum:
      payload.contractInfo?.endDate != undefined &&
      payload.contractInfo?.endDate != ''
        ? format(new Date(payload.contractInfo?.endDate), 'dd.MM.yyyy')
        : t('unlimited'),
    Nutzungstarif:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.fixTariff != undefined
          ? `${formatDecimal(payload.contractInfo?.fixTariff)} €/Monat`
          : null
        : null,
    Ladestromtarif:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.varTariff != undefined
          ? `${formatDecimal(payload.contractInfo?.varTariff)} €/kWh`
          : null
        : null,
    Bereitstellungsgebühr:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.onboardingFee != undefined
          ? `${formatDecimal(payload.contractInfo?.onboardingFee)} €`
          : null
        : null,
    Ladevorgangsgebühr:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.fixTariffPerChargingRequest != undefined
          ? `${formatDecimal(payload.contractInfo?.fixTariffPerChargingRequest)} €/Ladevorgang`
          : null
        : null,
    Zahlungsmethode:
      payload?.paymentMethod === 'BANK_TRANSFER'
        ? t('rechnung')
        : payload?.paymentMethod === 'STRIPE_SEPA'
          ? t('sepaStripemode')
          : t('sepa'),
    Mandatsreferenz: payload?.contractPaymentDetails?.contractMandateReference
      ? payload?.contractPaymentDetails?.contractMandateReference
      : null,
    'Debitorkonto-Nr.': payload?.erpSystemParameters?.debitAccountNr
      ? payload?.erpSystemParameters?.debitAccountNr
      : null,
    'Kostenstellen-Nr': payload?.erpSystemParameters?.costCenterNumber
      ? payload?.erpSystemParameters?.costCenterNumber
      : null,
    Umsatzsteuerkennung: payload?.erpSystemParameters?.vatId
      ? `${payload.erpSystemParameters?.vatId} %`
      : null,
  };

  const regularNoBillingRenderData = {
    UserType:
      userTypeListWithTranlations(t)[payload?.userType] || payload?.userType,
    Name: payload.firstName + ' ' + payload.lastName,
    Rechnungsadresse: [
      payload.contractAddress?.careOf
        ? `c/o ${payload.contractAddress?.careOf}`
        : '',
      payload.contractAddress?.street,
      payload.contractAddress?.zipCode + ' ' + payload.contractAddress?.city,
    ],
    'E-Mail': payload.email,
    Standort: payload.locationId + ':' + payload?.locationName,
    Parkplaznummer: payload.parkingLotNumbers,
    'Authentifizierung über':
      payload.authType === 'APP'
        ? 'Mobile App'
        : payload.authType === 'APP_AND_RFID'
          ? 'APP, RFID'
          : 'RFID-Karte',
    'RFID-Tags': payload.rfidTags,
    'RFID-serial-numbers': payload.rfidSTags,
    Abrechnungsart:
      payload.billingType === 'WITH_BILLING' ? 'Kostenpflichtig' : 'Kostenfrei',
    'Nutzungs- und Ladestromvertrag': payload.contractInfo?.contractPDF?.name,
    Startdatum:
      payload.contractInfo?.startDate != undefined
        ? format(new Date(payload.contractInfo?.startDate), 'dd.MM.yyyy')
        : null,
    Enddatum: payload.contractInfo?.endDate
      ? format(new Date(payload.contractInfo?.endDate), 'dd.MM.yyyy')
      : t('unlimited'),
    'Debitorkonto-Nr.': payload?.erpSystemParameters?.debitAccountNr
      ? payload?.erpSystemParameters?.debitAccountNr
      : null,
    'Kostenstellen-Nr': payload?.erpSystemParameters?.costCenterNumber
      ? payload?.erpSystemParameters?.costCenterNumber
      : null,
    Umsatzsteuerkennung: payload?.erpSystemParameters?.vatId
      ? `${payload.erpSystemParameters?.vatId} %`
      : null,
  };
  const localAdminWithBillingRenderData = {
    UserType:
      userTypeListWithTranlations(t)[payload?.userType] || payload?.userType,
    Name: payload.firstName + ' ' + payload.lastName,
    Rechnungsadresse: [
      payload.contractAddress?.careOf
        ? `c/o ${payload.contractAddress?.careOf}`
        : '',
      payload.contractAddress?.street,
      payload.contractAddress?.zipCode + ' ' + payload.contractAddress?.city,
    ],
    'E-Mail': payload.email,
    'Authentifizierung über':
      payload.authType === 'APP'
        ? 'Mobile App'
        : payload.authType === 'APP_AND_RFID'
          ? 'APP, RFID'
          : 'RFID-Karte',
    'RFID-Tags': payload.rfidTags,
    'RFID-serial-numbers': payload.rfidSTags,
    Abrechnungsart:
      payload.billingType === 'WITH_BILLING' ? 'Kostenpflichtig' : 'Kostenfrei',
    'Nutzungs- und Ladestromvertrag': payload.contractInfo?.contractPDF?.name,
    Startdatum:
      payload.contractInfo?.startDate != undefined
        ? format(new Date(payload.contractInfo?.startDate), 'dd.MM.yyyy')
        : null,
    Enddatum: payload.contractInfo?.endDate
      ? format(new Date(payload.contractInfo?.endDate), 'dd.MM.yyyy')
      : t('unlimited'),
    Nutzungstarif:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.fixTariff != undefined
          ? `${formatDecimal(payload.contractInfo?.fixTariff)} €/Monat`
          : null
        : null,
    Ladestromtarif:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.varTariff != undefined
          ? `${formatDecimal(payload.contractInfo?.varTariff)} €/kWh`
          : null
        : null,

    Bereitstellungsgebühr:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.onboardingFee != undefined
          ? `${formatDecimal(payload.contractInfo?.onboardingFee)} €`
          : null
        : null,
    Ladevorgangsgebühr:
      payload.billingType === 'WITH_BILLING'
        ? payload.contractInfo?.fixTariffPerChargingRequest != undefined
          ? `${formatDecimal(payload.contractInfo?.fixTariffPerChargingRequest)} €/Ladevorgang`
          : null
        : null,
    Zahlungsmethode:
      payload?.paymentMethod === 'BANK_TRANSFER'
        ? t('rechnung')
        : payload?.paymentMethod === 'STRIPE_SEPA'
          ? t('sepaStripemode')
          : t('sepa'),
    Mandatsreferenz: payload?.contractPaymentDetails?.contractMandateReference
      ? payload?.contractPaymentDetails?.contractMandateReference
      : null,
    'Debitorkonto-Nr.': payload?.erpSystemParameters?.debitAccountNr
      ? payload?.erpSystemParameters?.debitAccountNr
      : null,
    'Kostenstellen-Nr': payload?.erpSystemParameters?.costCenterNumber
      ? payload?.erpSystemParameters?.costCenterNumber
      : null,
    Umsatzsteuerkennung: payload?.erpSystemParameters?.vatId
      ? `${payload.erpSystemParameters?.vatId} %`
      : null,
  };
  const localAdminOrGlobalAdminNoBillingRenderData = {
    UserType:
      userTypeListWithTranlations(t)[payload?.userType] || payload?.userType,
    Name: payload.firstName + ' ' + payload.lastName,
    Rechnungsadresse: [
      payload.contractAddress?.careOf
        ? `c/o ${payload.contractAddress?.careOf}`
        : '',
      payload.contractAddress?.street,
      payload.contractAddress?.zipCode + ' ' + payload.contractAddress?.city,
    ],
    'E-Mail': payload.email,
    'Authentifizierung über':
      payload.authType === 'APP'
        ? 'Mobile App'
        : payload.authType === 'APP_AND_RFID'
          ? 'APP, RFID'
          : 'RFID-Karte',
    'RFID-Tags': payload.rfidTags,
    'RFID-serial-numbers': payload.rfidSTags,
    Abrechnungsart: 'Kostenfrei',
    'Nutzungs- und Ladestromvertrag': payload.contractInfo?.contractPDF?.name,
    Startdatum:
      payload.contractInfo?.startDate != undefined
        ? format(new Date(payload.contractInfo?.startDate), 'dd.MM.yyyy')
        : null,
    Enddatum: payload.contractInfo?.endDate
      ? format(new Date(payload.contractInfo?.endDate), 'dd.MM.yyyy')
      : t('unlimited'),
    'Debitorkonto-Nr.': payload?.erpSystemParameters?.debitAccountNr
      ? payload?.erpSystemParameters?.debitAccountNr
      : null,
    'Kostenstellen-Nr': payload?.erpSystemParameters?.costCenterNumber
      ? payload?.erpSystemParameters?.costCenterNumber
      : null,
    Umsatzsteuerkennung: payload?.erpSystemParameters?.vatId
      ? `${payload.erpSystemParameters?.vatId} %`
      : null,
  };

  const noBillingRenderData = {
    UserType:
      userTypeListWithTranlations(t)[payload?.userType] || payload?.userType,
    Name: payload.firstName + ' ' + payload.lastName,
    Rechnungsadresse: [
      payload.contractAddress?.careOf
        ? `c/o ${payload.contractAddress?.careOf}`
        : '',
      payload.contractAddress?.street,
      payload.contractAddress?.zipCode + ' ' + payload.contractAddress?.city,
    ],
    'E-Mail': payload.email,
    Standort: payload.locationId + ':' + payload?.locationName,
    Parkplaznummer: payload.parkingLotNumbers,
    'Authentifizierung über':
      payload.authType === 'APP'
        ? 'Mobile App'
        : payload.authType === 'APP_AND_RFID'
          ? 'APP, RFID'
          : 'RFID-Karte',
    'RFID-Tags': payload.rfidTags,
    'RFID-serial-numbers': payload.rfidSTags,
    Abrechnungsart: 'Kostenfrei',
    'Nutzungs- und Ladestromvertrag': payload.contractInfo?.contractPDF?.name,
    Startdatum:
      payload.contractInfo?.startDate != undefined
        ? format(new Date(payload.contractInfo?.startDate), 'dd.MM.yyyy')
        : null,
    Enddatum: payload.contractInfo?.endDate
      ? format(new Date(payload.contractInfo?.endDate), 'dd.MM.yyyy')
      : t('unlimited'),
    'Debitorkonto-Nr.': payload?.erpSystemParameters?.debitAccountNr
      ? payload?.erpSystemParameters?.debitAccountNr
      : null,
    'Kostenstellen-Nr': payload?.erpSystemParameters?.costCenterNumber
      ? payload?.erpSystemParameters?.costCenterNumber
      : null,
    Umsatzsteuerkennung: payload?.erpSystemParameters?.vatId
      ? `${payload.erpSystemParameters?.vatId} %`
      : null,
  };

  const renderData =
    payload?.userType === 'REGULAR' && payload?.billingType == 'WITH_BILLING'
      ? regularWithBillingRenderData
      : payload?.userType === 'REGULAR' && payload?.billingType == 'NO_BILLING'
        ? regularNoBillingRenderData
        : payload?.userType === 'LOCAL_ADMIN' &&
            payload?.billingType == 'WITH_BILLING'
          ? localAdminWithBillingRenderData
          : (payload?.userType === 'LOCAL_ADMIN' ||
                payload?.userType === 'GLOBAL_ADMIN') &&
              payload?.billingType === 'NO_BILLING'
            ? localAdminOrGlobalAdminNoBillingRenderData
            : noBillingRenderData;

  const createUser = async () => {
    try {
      if (payload?.rfidTags) {
        let trimmedRFId = payload?.rfidTags.map((element) => {
          return element.trim();
        });
        payload.rfidTags = trimmedRFId;
      }
      if (payload?.rfidSTags) {
        let trimmedSRFId = payload?.rfidSTags.map((element) => {
          return element.trim();
        });
        payload.rfidSerialId = trimmedSRFId;
        delete payload.rfidSTags;
      }
      const file = payload.contractInfo.contractPDF;
      let contractPdfInByteArrayForm;
      if (file) {
        const _base64 = await getBase64(file);
        const [, base64] = _base64.split(',');

        contractPdfInByteArrayForm = Array.from(
          new Uint8Array(base64ToArrayBuffer(base64))
        );
      }
      const newPayload = {
        ...payload,
        cpoId: payload.cpoId,
        contractInfo: {
          ...payload.contractInfo,
          contractPdfInByteArrayForm,
          startDate: format(payload.contractInfo.startDate, 'yyyy-MM-dd'),
          endDate: payload.contractInfo?.endDate
            ? format(new Date(payload.contractInfo?.endDate), 'yyyy-MM-dd')
            : null,
        },
        locationId: payload.locationId
          ? payload.locationId.split(':')[0]
          : null,
      };
      delete newPayload.contractInfo.contractPDF;
      const newPayloadEntries = Object.entries(newPayload)
        .map(([key, value]) => {
          if (!value) return null;
          if (typeof value === 'object') {
            for (const field in value) {
              if (!value[field]) delete value[field];
            }
          }
          return [key, value];
        })
        .filter(Boolean);
      const filteredPayload = Object.fromEntries(newPayloadEntries);
      await contractManagement.post('/create-contract', filteredPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
      mutate(['/contracts/operator', token, 'contractManagement']);
    } catch (error) {
      if (error?.error?.errorCodeResponse === 'ERR_RFID_ID_EXISTS') {
        setrfidError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === 'ERR_RFID_SERIAL_NOT_EXISTS'
      ) {
        setrfidSError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === errorCodes.duplicateEmail
      ) {
        setemailError(true);
        setEndCustomerId(error?.error?.errorMessage);
      } else {
        setError(true);
      }
    }
  };
  const updateUser = async () => {
    try {
      if (payload?.rfidTags) {
        let trimmedRFId = payload?.rfidTags.map((element) => {
          return element.trim();
        });
        payload.rfidTags = trimmedRFId;
      }
      if (payload?.rfidSTags) {
        let trimmedSRFId = payload?.rfidSTags.map((element) => {
          return element.trim();
        });
        payload.rfidSerialId = trimmedSRFId;
        delete payload.rfidSTags;
      }
      const file = payload.contractInfo.contractPDF;
      let contractPdfInByteArrayForm;
      if (file) {
        const _base64 = await getBase64(file);
        const [, base64] = _base64.split(',');

        contractPdfInByteArrayForm = Array.from(
          new Uint8Array(base64ToArrayBuffer(base64))
        );
      }
      if (payload?.paymentMethod == 'BANK_TRANSFER') {
        delete payload.contractPaymentDetails.contractMandateReference;
      }
      const newPayload = {
        ...payload,
        contractInfo: {
          ...payload.contractInfo,
          contractPdfInByteArrayForm,
          startDate: format(
            new Date(payload.contractInfo.startDate),
            'yyyy-MM-dd'
          ),
          endDate: payload.contractInfo?.endDate
            ? format(new Date(payload.contractInfo?.endDate), 'yyyy-MM-dd')
            : null,
        },
        locationId: payload.locationId
          ? payload.locationId.split(':')[0]
          : null,
        authCode: contract?.authCode,
        cpoId: contract?.cpoId,
        contractKey: contract?.contractKey,
      };
      delete newPayload.contractInfo.contractPDF;
      const newPayloadEntries = Object.entries(newPayload)
        .map(([key, value]) => {
          if (!value) return null;
          if (typeof value === 'object') {
            for (const field in value) {
              if (!value[field]) delete value[field];
            }
          }
          return [key, value];
        })
        .filter(Boolean);
      const filteredPayload = Object.fromEntries(newPayloadEntries);
      await contractManagement.put('/update-contract', filteredPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
      mutate(['/contracts/operator', token, 'contractManagement']);
    } catch (error) {
      if (error?.error?.errorCodeResponse === 'ERR_RFID_ID_EXISTS') {
        setrfidError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === 'ERR_RFID_SERIAL_NOT_EXISTS'
      ) {
        setrfidSError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === errorCodes.duplicateEmail
      ) {
        setemailError(true);
        setEndCustomerId(error?.error?.errorMessage);
      } else {
        setError(true);
      }
    }
  };

  const renderKey = (key) => {
    switch (key) {
      case 'Rechnungsadresse':
        return t('billingadr');
      case 'Standort':
        return t('LBLLocation');
      case 'Parkplaznummer':
        return t('parkingnum');
      case 'Authentifizierung über':
        return t('LBLAuth');
      case 'Kostenpflichtig':
        return t('chargable');
      case 'Kostenfrei':
        return t('free');
      case 'Ladevorgangsgebühr':
        return t('fixTariffPerChargingRequest');
      case 'Bereitstellungsgebühr':
        return t('onboardingFee');
      case 'Nutzungs- und Ladestromvertrag':
        return t('usageandcurrentcontr');
      case 'Nutzungstarif':
        return t('tarif');
      case 'Ladestromtarif':
        return t('chargintarif');
      case 'Mandatsreferenz':
        return t('mandateref');
      case 'Debitorkonto-Nr.':
        return t('custacnum');
      case 'Kostenstellen-Nr':
        return t('costcnum');
      case 'Umsatzsteuerkennung':
        return t('vatId');
      case 'Zahlungsmethode':
        return t('paymethod');
      case 'Rechnung':
        return t('rechnung');
      case 'SEPA-Lastschrift':
        return t('sepa');
      case 'Stripe':
        return t('sepaStripemode');
      case 'Startdatum':
        return t('LBLStartDate');
      case 'RFID-serial-numbers':
        return t('rfIdSN');
      case 'Enddatum':
        return t('LBLEndDate');
      case 'UserType':
        return t('LBLUserType');
      default:
        return key;
    }
  };

  return (
    <>
      {Object.entries(renderData).map(([key, value]) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={4}
        >
          {value ? (
            <>
              <Typography variant="body1">{renderKey(key)}</Typography>
              {typeof value === 'string' ? (
                <Typography variant="body1" align="right" id={key}>
                  {value}
                </Typography>
              ) : key == 'Parkplaznummer' ? (
                <Typography variant="body1" align="right" id={key}>
                  {value.join(',')}
                </Typography>
              ) : key == 'RFID-Tags' ? (
                <Typography variant="body1" align="right">
                  <OverflowText label={value.join(',')} maxwidthValue={'150px'}>
                    {' '}
                  </OverflowText>
                </Typography>
              ) : (
                <Stack my={2}>
                  {value.map((v) => (
                    <Typography variant="body1" align="right" id={v} key={v}>
                      {v}
                    </Typography>
                  ))}
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      ))}
    </>
  );
}
ConfirmUser.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any).isRequired,
  openFeedback: PropTypes.func.isRequired,
};

export default ConfirmUser;