import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography as MuiTypography,
  Button,
  Stack,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import { spacing } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Table from './Table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import shadows from '@mui/material/styles/shadows';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import dayjs from 'dayjs';
import BarChart from './BarChart';
const Typography = styled(MuiTypography)(spacing);
import { useTokenUtils } from '../../services/utils/tokenUtils';
import CleanChargingRequestDialog from './Components/CleanChargingRequestDialog';

function calculatedates(days) {
  let date = new Date();
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}

function Default() {
  const { t, i18n } = useTranslation();

  const [startDate, setStartDate] = useState(calculatedates(7));
  const [endDate, setEndDate] = useState(calculatedates(0));
  const [anchorSearchType, setAnchorSearchType] = useState(null);
  const [searchType, setSearchType] = useState('Select Filter');
  const [searchTypeParam, setSearchTypeParam] = useState('Select Filter');
  const [searchValue, setSearchValue] = useState('');
  const [formattedText, setFormattedText] = useState('');
  const [changeFlag, setChangeFlag] = useState(null);
  const [isCleanChargingReq, setIsCleanChargingReq] = useState(false);
  const initialValues = {
    startDate: startDate,
    endDate: endDate,
    searchValue: searchValue,
  };
  const { isAdminRole } = useTokenUtils();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        //searchValue: Yup.string().label(t('searchValue')),
        startDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .required(i18n.t('LBLReqCockpit')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .min(Yup.ref('startDate'), i18n.t('LBLENdgStart'))
          .required(i18n.t('LBLEndDateReq')),
      }),
    [t, i18n.language]
  );
  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setSearchValue(values.searchValue);
    setSearchTypeParam(searchType);
    setChangeFlag(new Date());
    actions.setSubmitting(false);
  };

  const handleSearchValueChange = (event, setFieldValue) => {
    let result = event.target.value;
    if (searchType == 'endCustomerId') {
      if (result.length > 13) {
        return null;
      } else {
        let value = event.target.value;
        // Remove any non-digit characters
        value = value.replace(/\D/g, '');
        // Insert hyphens after 3rd and 7th characters
        if (value.length > 3) {
          value = value.slice(0, 3) + '-' + value.slice(3);
        }
        if (value.length > 8) {
          value = value.slice(0, 8) + '-' + value.slice(8);
        }
        setFormattedText(value);
        setSearchValue(value);
        setFieldValue('searchValue', value);
      }
    } else {
      if (result.length > 22) {
        return null;
      } else {
        setSearchValue(result);
        setFieldValue('searchValue', result);
        setFormattedText(result);
      }
    }
  };

  const handleStartDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
    }
  };

  const handleEndDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
    }
  };

  const handleSearchTypeChange = (event) => {
    if (event.target.getAttribute('value') !== null) {
      setFormattedText('');
      let value = event.target.getAttribute('value');
      switch (value) {
        case 'Select Filter':
          setSearchType('Select Filter');
          break;
        case 'transactionId':
          setSearchType('transactionId');
          break;
        case 'endCustomerId':
          setSearchType('endCustomerId');
          break;
        default:
          break;
      }
      // setSearchType(value);
    }
    setAnchorSearchType(null);
  };

  const handleSearchTypeClick = (event) => {
    setAnchorSearchType(event.currentTarget);
  };

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography variant="subtitle1">
                {t('LBLCockpitTitle')}
                <span role="img" aria-label="Waving Hand Sign">
                  👋
                </span>
              </Typography>
              <Typography variant="h3" gutterBottom>
                {t('LBLCockpit')}
              </Typography>
            </div>
            {isAdminRole ? (
              <Button
                variant="contained"
                sx={{ width: '200px' }}
                id="closeFeedback"
                onClick={() => {
                  setIsCleanChargingReq(true);
                }}
              >
                {t('BTNCleanCRS')}
              </Button>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} lg={12} md={4}>
          <BarChart />
        </Grid>
      </Grid>
      <Grid item xs={12} xl={3} py={1} display="flex" justifyContent="flex-end">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form noValidate>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Button
                  endIcon={<ArrowDropDownIcon fontSize="small" />}
                  size="medium"
                  variant="contained"
                  color="primary"
                  aria-owns={anchorSearchType ? 'search-Type' : undefined}
                  aria-haspopup="true"
                  onClick={handleSearchTypeClick}
                  ml={4}
                >
                  {searchType == 'transactionId'
                    ? t('searchTypeTransactionId')
                    : searchType == 'Select Filter'
                      ? t('selectFilter')
                      : t('searchTypeEndCustomerId')}
                </Button>
                <Menu
                  id="search-Type"
                  anchorEl={anchorSearchType}
                  open={Boolean(anchorSearchType)}
                  onClose={handleSearchTypeChange}
                >
                  <MenuItem
                    key={'Select Filter'}
                    name="datarange"
                    value={'Select Filter'}
                    onClick={handleSearchTypeChange}
                  >
                    {t('selectFilter')}
                  </MenuItem>
                  <MenuItem
                    key={t('searchTypeTransactionId')}
                    name="datarange"
                    value={'transactionId'}
                    onClick={handleSearchTypeChange}
                  >
                    {t('searchTypeTransactionId')}
                  </MenuItem>
                  <MenuItem
                    key={t('searchTypeEndCustomerId')}
                    name="datarange"
                    value={'endCustomerId'}
                    onClick={handleSearchTypeChange}
                  >
                    {t('searchTypeEndCustomerId')}
                  </MenuItem>
                </Menu>

                <Field
                  margin="none"
                  component={TextField}
                  id="searchValue"
                  disabled={searchType == 'Select Filter'}
                  name="searchValue"
                  label={t('searchValue')}
                  TextField
                  onChange={(e) => {
                    handleSearchValueChange(e, setFieldValue);
                  }}
                  value={formattedText}
                  helperText={touched.searchValue && errors.searchValue}
                />

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    label={t('LBLStartDate')}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.startDate}
                    onChange={(newValue) =>
                      handleStartDateChange(newValue, setFieldValue)
                    }
                    renderInput={(params) => (
                      <Field
                        id="startDate"
                        component={TextField}
                        {...params}
                        required
                        name="startDate"
                        margin="none"
                        autoComplete="off"
                        helperText={errors.startDate}
                        error={errors.startDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    label={t('LBLEndDate')}
                    value={values.endDate}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue, setFieldValue);
                    }}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        name="endDate"
                        margin="none"
                        autoComplete="off"
                        helperText={errors.endDate}
                        error={errors.endDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={errors.startDate || errors.endDate}
                >
                  {t('BTNSubmit')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid
        justifyContent="space-between"
        style={{ width: '100%', overflow: 'auto' }}
        container
        spacing={2}
      >
        <div style={{ height: '20px', width: '100%', float: 'left' }}></div>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Table
              startDate={startDate}
              endDate={endDate}
              filterParam={searchTypeParam}
              filterValue={searchValue}
              changeFlag={changeFlag}
            />
          </Grid>
        </Grid>
      </Grid>
      <CleanChargingRequestDialog
        open={isCleanChargingReq}
        title={t('LBLCleanCRsReq')}
        message={t('LBLCleanCRsMsg')}
        handleClose={() => {
          setIsCleanChargingReq(false);
        }}
      />
    </React.Fragment>
  );
}
export default Default;