import { useAuth } from '../../../services/contexts';
import React, { useEffect } from 'react';
import { bookingService } from '../../../services/utils/axios';
import { Spacer, View } from '../../UserDetail/styles';
import { CardContent } from '@mui/material';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
function ConfirmOperatorERP({
  payload,
  submit,
  setError,
  setSubmit,
  openFeedback,
}) {
  const { t } = useTranslation();
  const { token } = useAuth();

  useEffect(() => {
    if (submit > 0) updateUser();
    setSubmit(0);
  }, [submit]);

  const updateUser = async () => {
    try {
      await bookingService.put('/bookings/update-erp-information', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <View>
        <CardContent>
          <Spacer mb={4} />
          <>
            <br></br>
            <Grid container spacing={1}>
              <Grid item md={6}>
                {t('clientNo')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.clientNumber != '' ? payload?.clientNumber : ''}
              </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={1}>
              <Grid item md={6}>
                {t('glNum')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.netLedgerAccountNr != ''
                  ? payload?.netLedgerAccountNr
                  : ''}
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={1}>
              <Grid item md={6}>
                {t('glNumVat')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.taxLedgerAccountNr != ''
                  ? payload?.taxLedgerAccountNr
                  : ''}
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={1}>
              <Grid item md={6}>
                {t('LBLpaymentMethod')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.paymentMethod != ''
                  ? payload?.paymentMethod === 'BANK_TRANSFER'
                    ? t('rechnung')
                    : payload?.paymentMethod === 'STRIPE_SEPA'
                      ? t('sepaStripemode')
                      : payload?.paymentMethod === 'SEPA'
                        ? t('LBLsepamode')
                        : payload?.paymentMethod
                  : ''}
              </Grid>
            </Grid>
          </>
        </CardContent>
      </View>
    </>
  );
}

export default ConfirmOperatorERP;