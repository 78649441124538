import React, { useCallback } from 'react';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography, Stack, Link, useTheme } from '@mui/material';
import { contractManagement } from '../../../../services/utils/axios';
import { useAuth } from '../../../../services/contexts/AuthContext';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
export default function CustumorComponent({
  contract,
  setErrorLabel,
  setSuccessLabel,
}) {
  const { t, i18n } = useTranslation();
  // added two labels to handle error and success
  const { token } = useAuth();
  const { mutate } = useSWRConfig();

  const nutzer = {
    Kundennummer: contract?.customer_id[0] || 'NA',
    Name: contract?.company_name[0] || 'NA',
    Address:
      `${contract?.street_name[0]} ${contract?.postcode[0]} ${contract?.city[0]} ` ||
      'NA',
    artDes: contract?.customer_type[0] || 'NA',
  };
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'artDes':
          return t('LBLCustType');
        case 'Kundennummer':
          return t('LBLCustomerNum');
        case 'Address':
          return t('LBLAddress');
        default:
          return key;
      }
    },
    [contract]
  );
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await contractManagement.post(
        `/send-invitation-email/${contract?.contractKey}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      mutate([
        `/send-invitation-email/${contract?.contractKey}`,
        token,
        'contractManagement',
      ]);
      setSuccessLabel(true);
    } catch (error) {
      setErrorLabel(true);
    }
  };
  const { status } = useTheme();
  return (
    <View>
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('LBLUserInfo')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          {nutzer && (
            <>
              {Object.entries(nutzer).map(([key, value]) => {
                return (
                  <>
                    {renderKey(key) && (
                      <>
                        <Item key={key}>
                          <Typography fontWeight="fontWeightMedium">
                            {renderKey(key)}
                          </Typography>
                          {key === 'Status' ? (
                            contract?.contractStatus == 'CREATED_UNSIGNED' ? (
                              <Stack
                                size="small"
                                mr={1}
                                mb={1}
                                color={status[value.toLowerCase()]}
                              >
                                <Link
                                  color="text.secondary"
                                  href="#"
                                  aria-describedby="ContactComponent-popover"
                                  onClick={handleClick}
                                >
                                  {value} (Einladungsmail erneut senden?)
                                </Link>
                              </Stack>
                            ) : (
                              <Typography
                                size="small"
                                mr={1}
                                mb={1}
                                color={status[value.toLowerCase()]}
                              >
                                {value}
                              </Typography>
                            )
                          ) : key === 'Address' ? (
                            <Typography
                              fontWeight="fontWeightRegular"
                              align="right"
                            >
                              {contract?.street_name[0]} <br />
                              {contract?.postcode[0]} {contract?.city[0]}
                            </Typography>
                          ) : (
                            <Typography
                              fontWeight="fontWeightRegular"
                              align="right"
                            >
                              {['string', 'number'].includes(typeof value)
                                ? value
                                : value?.join(', ') || null}
                            </Typography>
                          )}
                        </Item>
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Typography>
      </CardContent>
    </View>
  );
}
